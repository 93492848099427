import React from "react"

// Libraries
import { Link } from "gatsby"

// Layout
import Layout from "layouts/layout-primary"

// Components
import SEO from "components/seo/"

const ComingSoon = () => {
  const navigationLinks = [
    {
      label: "Home",
      url: "/",
    },
    {
      label: "Vision",
      url: "https://ndb.city",
    },
    {
      label: "Technology",
      url: "/technology",
    },
    {
      label: "Learn",
      url: "/learn",
    },
    {
      label: "Contact Us",
      url: "/#contactUs",
    },
  ]

  return (
    <Layout footerLess>
      <SEO title="Coming Soon | NDB" />
      <section className="coming-soon section">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-8 col-md-5">
              <h1 className="highlight mb-4">Coming soon</h1>
              <h3 className="subtitle">
                We are currently working on additional features That will be
                launched soon.
              </h3>
            </div>

            <div className="col-12 col-sm-4 col-md-3">
              <ul className="d-flex flex-column">
                {navigationLinks.map(link => (
                  <li>
                    <Link to={link.url} className="font-weight--700">
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ComingSoon
